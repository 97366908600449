.p-datatable-table th,
.p-datatable-table tr:nth-child(even) {
	//   background-color: #4da31414;
	@apply bg-[#4da31414];
}

.p-datatable .p-datatable-thead > tr > th {
	@apply text-[13px] font-semibold uppercase;
	padding: 0.4rem;
	border-left: 3px solid white;
	border-right: 3px solid white;
	//   width: 100%;
}

.p-datatable-table tr {
	@apply cursor-pointer;
}

.p-datatable td {
	padding: 0.4rem;
	//   @apply text-base;
	border-left: 3px solid white;
	border-right: 3px solid white;
}

.primary-button {
	@apply bg-secondary text-white rounded-md px-3 py-2 flex items-center justify-center text-sm md:mt-0 md:m-0 m-auto mt-4 md:text-left text-center hover:bg-secondary-light hover:transition-all;
}

.p-dropdown {
	@apply w-full;

	.p-dropdown-label {
		@apply pt-0 pb-0 pl-0;
	}
}

.p-selectbutton {
	@apply h-full;

	.p-button {
		@apply px-4 py-2 text-sm h-full rounded;

		&:focus {
			outline: none;
			box-shadow: none;
		}

		.p-button-label {
			@apply font-medium;
		}
	}
}

// Error
.p-inputtext.ng-touched.ng-invalid,
p-dropdown.ng-touched.ng-invalid,
p-calendar.ng-touched.ng-invalid {
	border: 1px solid #e24c4c !important;
}

// Confirm Dialog styles
.p-confirm-dialog-reject {
	@apply bg-[#ff4040e5] text-white rounded-md px-3 py-2 text-sm mr-2 outline-none shadow-none;
}

.p-confirm-dialog-accept {
	@apply bg-secondary text-white rounded-md px-3 py-2 text-sm outline-none shadow-none;
}

.p-chips-multiple-container {
	@apply w-full;

	&:focus-visible {
		outline: none !important;
		box-shadow: none !important;
	}
}

.p-chips-input-token {
	@apply p-0;
}

.p-paginator {
	@apply mt-1;
}

angledoublelefticon,
angledoublerighticon,
anglelefticon,
anglerighticon {
	.p-icon {
		height: 12px;
	}
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
	@apply h-8 md:h-10 min-w-[2rem] md:min-w-[2rem];
}

.p-paginator .p-paginator-pages .p-paginator-page {
	min-width: 2.5rem;
	height: 2.5rem;
	font-size: 14px;
	border: none;
}

// tooltip Styling
.p-tooltip .p-tooltip-text {
	background-color: #7c7c7cc8 !important;
	color: white !important;
}

.p-rating {
	@apply gap-[1px];
	.p-rating-item .p-rating-icon {
		color: #c4c4c4;
	}
	.p-rating-item.p-rating-item-active .p-rating-icon {
		color: #ecc028;
	}
}

// Stepper style
.p-stepper .p-stepper-header.p-highlight .p-stepper-number {
	@apply text-secondary-dark bg-secondary-100;
}
.p-stepper .p-stepper-header:has(~ .p-highlight) .p-stepper-separator {
	@apply bg-secondary;
}
