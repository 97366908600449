@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

@import "swiper/css";
@import "swiper/css/navigation";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "ngx-sharebuttons/themes/default";
@import "prime-modification.scss";

:root {
	--primary-color: #e7e7e7;
	--secondary-color: #4ea314;
	--white: #fff;
	--primary-font: "Poppins", sans-serif;
	--secondary-font: "Montserrat", sans-serif;
	--territory-font: "Open Sans", sans-serif;
}

* {
	box-sizing: border-box;
}

html,
body {
	padding: 0;
	margin: 0;
	background: white;
	font-family: var(--primary-font) !important;
	height: 100%;
}

.no-scrollbar::-webkit-scrollbar {
	display: none;
}

// phone input full width
ngx-intl-tel-input .iti {
	width: 100% !important;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
	-ms-overflow-style: none;
	/* IE and Edge */
	scrollbar-width: none;
	/* Firefox */
}

p {
	margin-block-start: 0;
	margin-block-end: 0;
}

.header {
	background: var(--secondary-color);
	display: grid;
	grid-template-columns: 320px auto;
	height: 128px;

	.branding {
		justify-self: center;
		align-self: center;
		height: 100%;
		width: 100%;
		display: flex;
		place-items: center;
		place-content: center;

		a {
			height: 100%;
			width: 100%;

			img {
				width: aut0;
				height: 100%;
				object-fit: cover;
			}
		}
	}

	nav {
		width: 100%;

		.menu {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 8px 0px;
			height: 54px;

			.left-menu {
				.menu-item {
					color: var(--white);
					padding: 8px 16px;
					text-decoration: none;
					border-right: 1px solid #107c02;
				}
			}

			.right-menu {
				display: flex;
				align-items: center;
				justify-content: center;

				.menu-item {
					color: var(--white);
					padding: 8px 16px;
					text-decoration: none;
					font-weight: 600;
					cursor: pointer;
				}

				.social-menu {
					.social-menu-item {
						color: var(--white);
						padding: 8px 12px;
						text-decoration: none;
						border-left: 1px solid #107c02;
					}
				}
			}

			.sign-in-menu {
				display: flex;
				justify-self: center;
				align-items: center;

				.menu-item {
					color: var(--white);
					padding: 8px 16px;
					text-decoration: none;
					font-weight: 600;
					cursor: pointer;
				}
			}

			.user-profile {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				color: var(--white);
				cursor: pointer;
				font-size: 0.8rem;
				letter-spacing: 0.6;
				position: relative;

				.account-profile {
					display: flex;
					justify-content: center;
					font-weight: 600;

					i {
						margin-left: 4px;
					}
				}

				#profile-menu {
					display: none;
					position: absolute;
					// z-index: 9;
					background: white;
					width: 200px;
					height: auto;
					box-sizing: border-box;
					padding: 12px;
					color: #000000;
					top: 40px;
					box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
					box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

					.profile-item {
						font-size: 0.9rem;
						font-weight: 500;
						cursor: pointer;
						padding: 12px 4px;
						letter-spacing: 0.6;
						display: flex;
						align-items: center;

						i {
							margin-right: 8px;
						}
					}

					&::backdrop {
						background: red;
						height: 100vh;
						width: 100vw;
					}
				}

				&:hover > #profile-menu {
					display: block;
				}
			}

			// .mobile-side-nav-button {
			//   display: none;
			//   visibility: hidden;
			//   opacity: 0;
			//   height: 0;
			//   width: 0;
			// }
		}

		.quick-items {
			padding: 8px;
			box-sizing: border-box;
			background: var(--white);
			display: flex;
			justify-content: flex-end;
			align-items: center;
			height: 80px;

			.quick-item {
				text-decoration: none;
				padding: 0 12px;
				color: black;
				border-right: 2px solid #e1e1e1;
				font-weight: 500;

				&:last-child {
					border-right: none;
				}
			}
		}
	}

	nav.mobile-nav {
		width: 60%;
		height: 100%;
		background: rgba($color: #000000, $alpha: 1);
		position: fixed;
		right: -380px;
		top: 0;
		z-index: 1;
		grid-template-rows: 45px auto 70px;
		padding: 0 16px;
		display: none;
		max-width: 350px;

		.close-button {
			all: unset;
			color: white;
			font-size: 24px;
			justify-self: flex-end;
			align-self: center;
		}

		.side-menu-items {
			.menu-item {
				color: var(--white);
				padding: 8px 16px;
				text-decoration: none;
				display: block;
			}

			.divider {
				border-bottom: 1px solid #717171;
				display: block;
				margin: 32px 16px;
			}
		}

		.social-menu {
			align-self: center;
			justify-self: center;

			.social-menu-item {
				color: var(--white);
				padding: 8px 12px;
				text-decoration: none;
			}
		}
	}
}

@media only all and (max-width: 1024px) {
	.header {
		grid-template-columns: auto 1fr;
		height: auto;

		.branding {
			width: 92px;
			height: 21px;
			justify-self: flex-start;
			align-self: flex-start;

			a {
				width: 92px;
				height: 21px;

				img {
					width: 100%;
				}
			}
		}

		nav {
			width: 100%;

			.menu {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				justify-content: space-between;

				.left-menu {
					.menu-item {
						border-right: none;
						padding: 8px 6px;
						font-size: 12px;
					}
				}

				.right-menu {
					display: none;
					position: absolute;
					left: 0;
					top: 0;
					height: 0;
					width: 0;
					opacity: 0;
					visibility: hidden;
				}

				.mobile-menu-buttons {
					display: flex;
					gap: 8px;
				}

				.mobile-side-nav-button {
					display: block;
					visibility: visible;
					opacity: 1;
					height: initial;
					width: initial;
					margin-right: 16px;
					cursor: pointer;
					color: white;
				}
			}

			.quick-items {
				display: none;
				position: absolute;
				left: 0;
				top: 0;
				height: 0;
				width: 0;
				opacity: 0;
				visibility: hidden;
			}
		}

		nav.mobile-nav-active {
			display: grid;
			right: 0;
			-webkit-animation: slide-in 50ms;
			/* Safari, Chrome and Opera > 12.1 */
			-moz-animation: slide-in 50ms;
			/* Firefox < 16 */
			-ms-animation: slide-in 50ms;
			/* Internet Explorer */
			-o-animation: slide-in 50ms;
			/* Opera < 12.1 */
			animation: slide-in 50ms;
		}

		nav.mobile-nav-inactive {
			right: -380px;
			-webkit-animation: slide-out 50ms;
			/* Safari, Chrome and Opera > 12.1 */
			-moz-animation: slide-out 50ms;
			/* Firefox < 16 */
			-ms-animation: slide-out 50ms;
			/* Internet Explorer */
			-o-animation: slide-out 50ms;
			/* Opera < 12.1 */
			animation: slide-out 50ms;
		}
	}
}

@keyframes slide-in {
	from {
		right: -380px;
	}

	to {
		right: 0;
	}
}

/* Firefox < 16 */
@-moz-keyframes slide-in {
	from {
		right: -380px;
	}

	to {
		right: 0;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slide-in {
	from {
		right: -380px;
	}

	to {
		right: 0;
	}
}

/* Internet Explorer */
@-ms-keyframes slide-in {
	from {
		right: -380px;
	}

	to {
		right: 0;
	}
}

/* Opera < 12.1 */
@-o-keyframes slide-in {
	from {
		right: -380px;
	}

	to {
		right: 0;
	}
}

@keyframes slide-out {
	from {
		right: 0;
	}

	to {
		right: -380px;
	}
}

/* Firefox < 16 */
@-moz-keyframes slide-out {
	from {
		right: 0;
	}

	to {
		right: -380px;
	}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes slide-out {
	from {
		right: 0;
	}

	to {
		right: -380px;
	}
}

/* Internet Explorer */
@-ms-keyframes slide-out {
	from {
		right: 0;
	}

	to {
		right: -380px;
	}
}

/* Opera < 12.1 */
@-o-keyframes slide-out {
	from {
		right: 0;
	}

	to {
		right: -380px;
	}
}

main {
	margin: 0 auto;
	max-width: 1920px;
}

.form-error {
	color: #ff4a4a;
	font-size: 12px;
	font-weight: 500;
}

.page-heading {
	// padding: 32px 0;
	padding-bottom: 16px;
	margin: 0 auto;

	.page-title {
		text-align: center;
		font-family: var(--primary-font);
		font-size: 36px;
		font-weight: 600;
		margin-block-start: 0;
		margin-block-end: 0;
	}

	.page-subtitle {
		font-family: var(--primary-font);
		// font-size: 18px;
		font-weight: 400;
		line-height: 27px;
	}
}

.custom-bread-crumb {
	padding-top: 14px !important;
	padding-left: 32px !important;
	background: var(--white) !important;
}

app-product-details {
	.swiper {
		width: 100%;
		height: 100%;

		.swiper-slide {
			text-align: center;
			font-size: 18px;
			// background: #fff;

			/* Center slide text vertically */
			display: -webkit-box;
			display: -ms-flexbox;
			display: -webkit-flex;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			-webkit-justify-content: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			-webkit-align-items: center;
			align-items: center;
			// border-radius: 15px;

			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				// border-radius: 15px;
			}
		}

		.swiper-button-prev,
		.swiper-rtl .swiper-button-next,
		.swiper-button-next,
		.swiper-rtl .swiper-button-prev {
			background: white;
			padding: 8px;
			width: 42px;
			height: 38px;
			border-radius: 14px;
		}

		.swiper-button-prev:after,
		.swiper-rtl .swiper-button-next:after,
		.swiper-button-next:after,
		.swiper-rtl .swiper-button-prev:after {
			font-size: 18px;
			font-weight: bolder;
			color: black;
		}
	}
}

@media only all and (max-width: 1024px) {
	.page-heading {
		padding: 0;

		.page-title {
			font-size: 24px;
		}

		// .page-subtitle {
		//   font-size: 14px;
		//   line-height: 18px;
		// }
	}
}

// .p-tabview-ink-bar {
//     background-color: var(--secondary-color);
// }

app-product-details {
	.p-highlight {
		@apply border-b-4 border-secondary;
	}
}

.input-style {
	@apply border-2 border-primary-light rounded-md px-1 py-2 placeholder:italic focus:outline-none focus-visible:border-primary-dark text-sm outline-none shadow-none;
}

.ck-editor__editable {
	min-height: 200px;
}

.like-filled {
	font-variation-settings: "FILL" 1, "wght" 700, "GRAD" 0, "opsz" 48;
}

app-add-topic {
	.p-chips-multiple-container {
		@apply w-full;

		&:focus-visible {
			outline: none !important;
			box-shadow: none !important;
		}
	}

	.p-chips-input-token {
		@apply p-0;
	}
}

app-general-forum,
app-table {
	p-sorticon {
		// display: flex;
		display: none;
	}

	@media only screen and (max-width: 767px) {
		.p-selectbutton .p-button {
			width: 50%;
		}
	}
}

.post-button {
	@apply flex items-center gap-1 p-1 rounded text-sm hover:bg-[#4da31410] transition-all;
}

.edit-button {
	@apply flex items-center gap-1 p-1 rounded text-sm transition-all bg-[#0080009c] text-white;
}

.delete-button {
	@apply flex items-center gap-1 p-1 rounded text-sm transition-all bg-[#ff4040e5] text-white;
}

button:disabled {
	@apply cursor-not-allowed opacity-50;
}

p-intl-tel-input {
	@apply w-full border-2 border-primary-light rounded-md px-1 py-2 placeholder:italic focus:outline-none focus-visible:border-primary-dark #{!important};

	p-dropdown {
		.p-dropdown-label {
			.align-items-center {
				@apply items-center;
			}
		}

		.p-dropdown-items {
			.align-items-center {
				@apply items-center;
			}
		}
	}

	input#phone {
		&:focus &:focus-visible {
			@apply shadow-none outline-none;
		}
	}
}

.forum-tag {
	@apply bg-secondary rounded-full px-3 py-2 flex items-center justify-center text-sm cursor-pointer font-medium min-w-[110px];
}

.post-content {
	img {
		@apply max-h-[300px] w-auto object-cover cursor-pointer;
	}
}

.likes-data {
	display: none;
}

.like-popup:hover .likes-data {
	//   display: block;
}

app-general-forum {
	.p-tabview {
		@apply shadow-none;
	}

	.p-tabview-nav {
		.p-highlight {
			.p-tabview-nav-link {
				@apply bg-transparent;
			}

			@apply text-secondary border-b-2 border-secondary;
		}
	}

	.p-tabview-panels {
		@apply p-0 #{!important};
	}
}

.img-shadow {
	-webkit-box-shadow: 0px 0px 23px 0px rgba(194, 194, 194, 0.66);
	-moz-box-shadow: 0px 0px 23px 0px rgba(194, 194, 194, 0.66);
	box-shadow: 0px 0px 23px 0px rgba(194, 194, 194, 0.66);
}

.sb-wrapper.sb-show-icon.sb-show-text {
	@apply m-0;
}

.social-button {
	img {
		@apply h-[18px] w-[18px] pointer-events-none select-none;
	}
	@apply flex gap-3 items-center justify-center p-2 rounded-md shadow-xl cursor-pointer w-full text-sm;
}

.google-button {
	@extend .social-button;
	@apply bg-white text-black hover:outline hover:outline-blue-300;
}

.x-button {
	@extend .social-button;
	svg {
		@apply text-white;
	}
	@apply bg-[#000000] text-white;
}
